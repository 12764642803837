import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Nav from "../components/nav"
import SEO from "../components/seo"
import PayPal from "../components/reusable/paypalbutton.js"
import "./text-audio.css"



const TextAudioTemplate = props => {
  return (
    <Layout>
      <SEO
        title={props.data.contentfulTextAudio.seoTitel}
        description={props.data.contentfulTextAudio.seoAnleser}
      ></SEO>
      <Nav />
      <div className="blog__header">
        <div
          className="blog__hero"
          style={{
            backgroundImage: `url(${props.data.contentfulTextAudio.titelbild.fluid.src})`,
          }}
        ></div>

        <div className="blog__info">
          <h1 className="blog__title">
            {props.data.contentfulTextAudio.titel}
          </h1>
        </div>
      </div>

      <div className="blog__wrapper">
        {props.data.contentfulTextAudio.audio.file.url !==
          "//assets.ctfassets.net/1gliqbmw156x/5fE3H0k3TTRdGcNar4Yuy5/3ae8f9c74a2002860ab41e1596683650/keinaudio.mp3" && (
            <div className="blog__audio">
              <audio
                src={props.data.contentfulTextAudio.audio.file.url}
                type="audio/mp3"
                controls
              >
                Audio nicht unterstützt
            </audio>
            </div>
          )}
        <div className="blog__content">
          <div
            dangerouslySetInnerHTML={{
              __html: `${props.data.contentfulTextAudio.text.childMarkdownRemark.html}`,
            }}
          />
        </div>
      </div>
      <PayPal />
    </Layout>
  )
}

export default TextAudioTemplate

export const query = graphql`
  query TextAudioTemplate($id: String!) {
    contentfulTextAudio(id: { eq: $id }) {
      titel
      id
      slug
      text {
        childMarkdownRemark {
          html
        }
      }
      seoTitel
      seoAnleser
      titelbild {
        fluid(maxWidth: 1200, quality: 100) {
          ...GatsbyContentfulFluid
          src
        }
      }
      audio {
        file {
          url
        }
      }
    }
  }
`
